import React, { Component } from "react";

export default class Discovery extends Component {
  
  componentDidMount() {
    const script = document.createElement("script");

    script.src = "https://formfacade.com/include/100696475651717806610/form/1FAIpQLScHfSpSE_iEi0M5sSJswWXMsYcsNKedaGU4_z-jp2MGeGA6MA/bootstrap.js?div=ff-compose";
    script.async = true;
    //script.src = "https://formfacade.com/include/100696475651717806610/form/1FAIpQLSegZGYF8dL1nrIp3pRdMy8kvdL_-3K9-aGgN97i8wLyJDyUSQ/classic.js?div=ff-compose";

    document.body.appendChild(script);
  }
  
  render() {
    return (
      <div style={{paddingTop: 100}}>
        <div id="ff-compose" />
        <div className="container" />
      </div>
    );
  }
}